export const ControlCenterIcon = ({color}: {color: string}) => (
  <svg
    aria-hidden="true"
    className="svg-inline--fa fa-chart-network fa-w-20"
    data-icon="chart-network"
    data-prefix="fad"
    focusable="false"
    height="100%"
    role="img"
    viewBox="0 0 640 512"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g className={`text-${color}-500 fa-group`}>
      <path
        className="fa-secondary"
        d="M64 240a64 64 0 1 0 64 64 64.06 64.06 0 0 0-64-64zm88 80h48v-32h-48zm294.4-106.8l19.2 25.6 48-36-19.2-25.6zM576 64a64 64 0 1 0 64 64 64.06 64.06 0 0 0-64-64z"
        fill="currentColor"
        key="secondary"
        opacity="0.4"
      />
      <path
        className="fa-primary"
        d="M576 384a63.84 63.84 0 0 0-38.3 13l-96-57.6a109.91 109.91 0 0 0 6.3-35.5 111.94 111.94 0 0 0-112-112 108.64 108.64 0 0 0-24.4 2.9l-40.8-87.4A63.84 63.84 0 1 0 224 128c1.1 0 2.1-.3 3.2-.3l41 87.8C241.5 235.9 224 267.8 224 304a111.71 111.71 0 0 0 193.2 76.7l95.8 57.5a63.87 63.87 0 1 0 63-54.2zm-240-32a48 48 0 1 1 48-48 48 48 0 0 1-48 48z"
        fill="currentColor"
        key="primary"
      />
    </g>
  </svg>
);
